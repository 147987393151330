// Libraries
import React, { useState } from "react"
import { Link } from "gatsby"
import * as styles from "./Navbar.module.scss"
import { useLocation } from "@reach/router"
import { AnchorLink } from "gatsby-plugin-anchor-links"
interface LinkProps {
  text: string
  link: string
  isHiglited?: "basic" | "outline"
  isCurrentLang?: boolean
  as?: "span" | "li"
}

export interface NavbarItemProps extends LinkProps {
  children?: LinkProps[]
  toggleExpandHandler: () => void
}

const NavbarItem = (props: NavbarItemProps) => {
  const {
    text,
    as = "li",
    link,
    children,
    isHiglited,
    toggleExpandHandler,
    isCurrentLang,
  } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const { pathname } = useLocation()

  const expandHandler = () => setIsExpanded(!isExpanded)

  const Tag = as

  return (
    <Tag
      className={styles.navbarMenuItem}
      data-is-higlited={isHiglited}
      data-is-current-lang={isCurrentLang}
      data-is-current-path={pathname.startsWith(link)}
      data-has-submenu={!!children?.length}
      onClick={() => {
        if (!!children?.length) {
          expandHandler()
        } else {
          toggleExpandHandler()
        }
      }}
    >
      {!!link ? (
        link.includes("http://") || link.includes("https://") ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : link.includes("#") ? (
          <AnchorLink to={link}>{text}</AnchorLink>
        ) : (
          <Link to={link}>{text}</Link>
        )
      ) : (
        <span>{text}</span>
      )}
      {!!children?.length && (
        <ul className={styles.navbarSubmenu} data-is-expanded={isExpanded}>
          <div className={styles.navbarSubmenuContainer}>
            {children.map(({ text, link }, index) => (
              <li
                key={text.slice(3) + index}
                className={styles.navbarSubmenuItem}
              >
                <Link onClick={toggleExpandHandler} to={link}>
                  {text}
                </Link>
              </li>
            ))}
          </div>
        </ul>
      )}
    </Tag>
  )
}

export default NavbarItem
